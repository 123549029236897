"use client";

import { create } from "zustand";
import {
  CartItem,
  Giftcards,
  ReserveCancelRegister,
  ReserveCancelRegisterSet,
  SeatUpgradeItem,
} from "~/lib/definitions";
import { makeRequests, persistShipmentMethod } from "~/lib/client/utils";
import { ShipmentMethod } from "@egocentric-systems/ts-apis/shop_config/types/v1/shipment_pb";
import { calculateCartExpireDelta, logIfDev } from "~/lib/utils";
import {
  gtmCancelSeat,
  gtmCartExpired,
  gtmReserveSeat,
  gtmSetSeat,
} from "~/lib/client/gtm";
import {
  getCartItems,
  getCustomer,
  getDiscounts,
  getGiftcards,
  getSeatUpgrades,
  getReservationToken,
  getShipmentMethod,
  saveCartItems,
  saveDiscounts,
  saveGiftcards,
  saveSeatUpgrades,
  saveReservationToken,
} from "~/lib/client/db";
import { CheckoutSchema } from "~/lib/client/schemas/checkout";
import { z } from "zod";
import { reservation } from "~/lib/client/reservation";
import { toast } from "~/components/ui/use-toast";
import { PlainMessage } from "@bufbuild/protobuf";
import {
  Giftcard,
  Voucher,
} from "@egocentric-systems/ts-apis/booking_gateway/v1/vouchers_pb";
import { vouchers } from "~/lib/client/vouchers";
import { ActiveBenefit } from "@egocentric-systems/ts-apis/voucher/types/v1/membership_pb";
import {
  DiscountConfig,
  SeatUpgrade,
  SeatUpgradeConfig,
} from "@egocentric-systems/ts-apis/booking/types/v1/order_pb";
import { createReserveOrCancelBundleRequest } from "~/lib/client/order";
import t from "~/lib/dictionaries/de.json";
import { FanTicket } from "@egocentric-systems/ts-apis/shop_config/types/v1/fanticket_pb";

export type UseItems = {
  currency: string;
  customer: z.infer<CheckoutSchema> | null;
  reservationTokenFetched: boolean;
  items: CartItem[];
  discounts: {
    vouchers: Array<Pick<PlainMessage<Voucher>, "id" | "code" | "discount">>;
    benefits: Array<
      Pick<PlainMessage<ActiveBenefit>, "id" | "name" | "discount">
    >;
    giftcards: Array<PlainMessage<Giftcard>>;
  };
  giftcards: Giftcards;
  seatUpgrades: SeatUpgradeItem[];
  removeItem: (item: CartItem) => Promise<void>;
  setItems: (items: CartItem[]) => void;
  setUiItems: (items: CartItem[]) => void;
  setTimer: (timer: string | null) => void;
  setReservationToken: (token: string | null) => void;
  loading: boolean;
  uiItems: CartItem[];
  reserveSeat: (register: Omit<ReserveCancelRegister, "discount">) => void;
  cancelSeat: (register: ReserveCancelRegister) => void;
  setSeats: (data: ReserveCancelRegisterSet) => void;
  manipulating: boolean;
  reservationToken: string | null;
  cartTimer: string | null;
  shipmentMethod: ShipmentMethod | null;
  setShipmentMethod: (method: ShipmentMethod) => void;
  addVoucher: (
    voucher: UseItems["discounts"]["vouchers"][number],
    item?: CartItem,
  ) => void;
  removeVoucher: (voucherId: string, removeFromItem?: boolean) => void;
  addBenefit: (
    benefit: UseItems["discounts"]["benefits"][number],
    item?: CartItem,
  ) => void;
  removeBenefit: (benefitId: string) => void;
  addFantickets: (
    fanTicket?: Pick<PlainMessage<FanTicket>, "eventIds">,
  ) => void;
  addFanticket: (item: CartItem) => void;
  removeFantickets: () => void;
  removeFanticket: (item: CartItem) => void;

  addGiftcard: (giftcard: Giftcards[number]) => void;
  removeGiftcard: (giftcardId: string) => void;

  redeemGiftcard: (
    giftcard: UseItems["discounts"]["giftcards"][number],
  ) => void;
  releaseGiftcard: (giftcardId: string) => void;

  addSeatUpgrade: (upgrade: SeatUpgradeItem) => void;
  removeSeatUpgrade: (upgrade: PlainMessage<SeatUpgradeConfig>) => void;
};

function findItemIndex(items: CartItem[], item: CartItem): number {
  return items.findIndex(
    ({ section, price, eventOrBundle, series, seat, discount }) =>
      section.id === item?.section?.id &&
      price.id === item.price.id &&
      eventOrBundle.case === item.eventOrBundle.case &&
      eventOrBundle.value.id === item.eventOrBundle.value.id &&
      series.id === item.series.id &&
      seat?.id === item.seat?.id &&
      discount?.case === item.discount?.case &&
      discount?.value === item.discount?.value,
  );
}

export function cartCheckInterval(): void {
  const { items, uiItems, discounts, manipulating, loading, reservationToken } =
    useCart.getState();

  if (manipulating || loading) return;

  if (reservationToken && (items.length === 0 || uiItems.length === 0)) {
    useCart.setState({
      currency: "EUR",
      items: [],
      uiItems: [],
      discounts: {
        vouchers: [],
        benefits: [],
        giftcards: discounts.giftcards,
      },
      cartTimer: null,
      reservationToken: null,
    });

    saveReservationToken(null).catch(logIfDev);
    saveCartItems([]).catch(logIfDev);
    saveDiscounts({
      vouchers: [],
      benefits: [],
      giftcards: discounts.giftcards,
    }).catch(logIfDev);

    return;
  }

  if (!reservationToken && (items.length > 0 || uiItems.length > 0)) {
    useCart.setState({
      currency: "EUR",
      items: [],
      uiItems: [],
      discounts: {
        vouchers: [],
        benefits: [],
        giftcards: discounts.giftcards,
      },
      cartTimer: null,
    });
    return;
  }

  if (reservationToken) {
    const timestamp = calculateCartExpireDelta(reservationToken);
    useCart.setState({ cartTimer: timestamp });

    if (!timestamp) {
      gtmCartExpired(items);

      saveReservationToken(null).catch(logIfDev);
      saveCartItems([]).catch(logIfDev);
      saveDiscounts({
        vouchers: [],
        benefits: [],
        giftcards: discounts.giftcards,
      }).catch(logIfDev);

      useCart.setState({
        currency: "EUR",
        items: [],
        uiItems: [],
        discounts: {
          vouchers: [],
          benefits: [],
          giftcards: discounts.giftcards,
        },
        cartTimer: null,
        reservationToken: null,
      });
    }
  } else {
    useCart.setState({
      currency: "EUR",
      items: [],
      uiItems: [],
      discounts: {
        vouchers: [],
        benefits: [],
        giftcards: discounts.giftcards,
      },
      cartTimer: null,
      reservationToken: null,
    });
  }
}

const cache = [];
let reserveTimeout: NodeJS.Timeout | null = null;
let cacheMergeTimeout: NodeJS.Timeout | null = null;

function calculateCurrency(items: CartItem[]): string {
  return items.reduce((acc, item) => {
    if (item.price.value?.currency) {
      return item.price.value.currency;
    }
    return acc;
  }, "EUR");
}

getShipmentMethod().then((method) => {
  if (typeof window === "undefined") return;
  useCart.setState({ shipmentMethod: method });
});

getReservationToken()
  .then((token) => {
    if (typeof window === "undefined") return;
    useCart.setState({
      reservationToken: token,
      reservationTokenFetched: true,
    });
  })
  .catch(() => {
    if (typeof window === "undefined") return;
    useCart.setState({ reservationTokenFetched: true });
  });

getCartItems().then((items) => {
  if (typeof window === "undefined") return;
  if (!items) {
    useCart.setState({ items: [], uiItems: [] });
    return;
  }

  useCart.setState({ items: items, uiItems: items });
});

getGiftcards().then((giftcards) => {
  if (typeof window === "undefined") return;
  if (!giftcards) {
    useCart.setState({ giftcards: [] });
    return;
  }

  useCart.setState({ giftcards });
});

getSeatUpgrades().then((seatUpgrades) => {
  if (typeof window === "undefined") return;
  if (!seatUpgrades) {
    useCart.setState({ seatUpgrades: [] });
    return;
  }

  useCart.setState({ seatUpgrades });
});

getDiscounts().then((discounts) => {
  if (typeof window === "undefined") return;
  if (!discounts) {
    useCart.setState({
      discounts: { vouchers: [], benefits: [], giftcards: [] },
    });
    return;
  }

  useCart.setState({
    discounts: {
      vouchers: discounts.vouchers ?? [],
      benefits: discounts.benefits ?? [],
      giftcards: discounts.giftcards ?? [],
    },
  });
});

getCustomer().then((customer) => {
  if (typeof window === "undefined") return;
  useCart.setState({ customer: customer });
});

export const useCart = create<UseItems>((set, get) => {
  const setItems = (items: CartItem[]): void => {
    set({ items: items, currency: calculateCurrency(items) });
    saveCartItems(items).catch(logIfDev);
  };

  const setUiItems = (items: CartItem[]): void => {
    set({ currency: calculateCurrency(items), uiItems: items });
  };

  const setTimer = (timer: string | null): void => {
    set({ cartTimer: timer });
  };

  const setReservationToken = (token: string | null): void => {
    set({ reservationToken: token, reservationTokenFetched: true });
    saveReservationToken(token).catch(logIfDev);
  };

  const setShipmentMethod = (method: ShipmentMethod): void => {
    set({ shipmentMethod: method });
    persistShipmentMethod(method);
  };

  const reserveSeat = (
    register: Omit<ReserveCancelRegister, "discount">,
  ): void => {
    clearTimeout(reserveTimeout as unknown as number);
    set({ manipulating: true });
    const items = structuredClone(get().uiItems);

    const index = items.findIndex(
      ({ section, price, eventOrBundle, series, seat, discount }) =>
        section.id === register.section.id &&
        price.id === register.price.id &&
        eventOrBundle.case === register.eventOrBundle.case &&
        eventOrBundle.value.id === register.eventOrBundle.value.id &&
        series.id === register.series.id &&
        seat?.id === register.seat?.id &&
        !discount,
    );

    if (index === -1) {
      items.push({
        ...structuredClone({
          ...register,
          discount: undefined,
        }),
        quantity: 1,
      });
    } else {
      items[index] = {
        ...items[index],
        quantity: items[index].quantity + 1,
      };
    }

    gtmReserveSeat(register, items);
    set({ uiItems: items });

    doMagic(register);
  };

  const cancelSeat = (register: ReserveCancelRegister): void => {
    clearTimeout(reserveTimeout as unknown as number);
    set({ manipulating: true });
    const items = structuredClone(get().uiItems);

    const index = items.findIndex(
      ({ section, price, eventOrBundle, series, seat, discount }) =>
        section.id === register.section.id &&
        price.id === register.price.id &&
        eventOrBundle.case === register.eventOrBundle.case &&
        eventOrBundle.value.id === register.eventOrBundle.value.id &&
        series.id === register.series.id &&
        seat?.id === register.seat?.id &&
        discount?.case === register.discount?.case &&
        discount?.value === register.discount?.value,
    );

    if (index === -1) {
      return;
    }

    items[index] = {
      ...items[index],
      quantity: items[index].quantity - 1,
    };

    gtmCancelSeat(register, items);
    set({ uiItems: items });

    doMagic(register);
  };

  const setSeats = (register: ReserveCancelRegisterSet): void => {
    clearTimeout(reserveTimeout as unknown as number);
    set({ manipulating: true });
    const items = structuredClone(get().uiItems);

    const index = items.findIndex(
      ({ section, price, eventOrBundle, series, seat, discount }) =>
        section.id === register.section.id &&
        price.id === register.price.id &&
        eventOrBundle.case === register.eventOrBundle.case &&
        eventOrBundle.value.id === register.eventOrBundle.value.id &&
        series.id === register.series.id &&
        seat?.id === register.seat?.id &&
        discount?.case === register.discount?.case &&
        discount?.value === register.discount?.value,
    );

    if (index === -1) {
      items.push({
        ...structuredClone({
          ...register,
        }),
      });
    } else {
      items[index] = {
        ...items[index],
        quantity: register.quantity,
      };
    }

    gtmSetSeat(register, items);
    set({ uiItems: items });

    doMagic(register);
  };

  async function removeItem(item: CartItem) {
    const token = get().reservationToken;

    if (!token) {
      return;
    }

    if (item.eventOrBundle.case === "event") {
      await reservation.cancelSeats({
        reservationToken: token,
        eventId: item.eventOrBundle.value.id,
        sectionId: item.section.id,
        seating: !item.seat
          ? { case: "seatCount", value: item.quantity }
          : { case: "seatIds", value: { ids: [item.seat.id] } },
      });
    } else {
      const reservationConfigs = createReserveOrCancelBundleRequest(item);

      for (const reservationConfig of reservationConfigs) {
        await reservation.cancelBundle({
          ...reservationConfig,
          reservationToken: useCart.getState().reservationToken ?? "",
        });
      }
    }

    if (item.discount) {
      if (item.discount.case === "voucherId") {
        vouchers.releaseVoucher({
          reservationToken: token,
          voucherId: item.discount.value,
        });

        removeVoucher(item.discount.value, false);
      } else if (item.discount.case === "benefitId") {
        vouchers.releaseBenefit({
          reservationToken: token,
          benefitId: item.discount.value,
        });

        removeBenefit(item.discount.value, false);
      }
    }

    const items = structuredClone(get().items);
    const uiItems = structuredClone(get().uiItems);

    const index = items.findIndex(
      ({ section, price, eventOrBundle, series, seat, discount }) =>
        section.id === item.section.id &&
        price.id === item.price.id &&
        eventOrBundle.case === item.eventOrBundle.case &&
        eventOrBundle.value.id === item.eventOrBundle.value.id &&
        series.id === item.series.id &&
        seat?.id === item.seat?.id &&
        discount?.case === item.discount?.case &&
        discount?.value === item.discount?.value,
    );

    if (index === -1) {
      return;
    }

    items.splice(index, 1);

    const uiIndex = uiItems.findIndex(
      ({ section, price, eventOrBundle, series, seat, discount }) =>
        section.id === item.section.id &&
        price.id === item.price.id &&
        eventOrBundle.case === item.eventOrBundle.case &&
        eventOrBundle.value.id === item.eventOrBundle.value.id &&
        series.id === item.series.id &&
        seat?.id === item.seat?.id &&
        discount?.case === item.discount?.case &&
        discount?.value === item.discount?.value,
    );

    if (uiIndex === -1) {
      return;
    }

    uiItems.splice(uiIndex, 1);

    setItems(items);
    setUiItems(uiItems);

    if (items.length === 0) {
      setReservationToken(null);
    }
  }

  const addVoucher: UseItems["addVoucher"] = (voucher, item) => {
    set(({ discounts }) => {
      const newVouchers = [...discounts.vouchers, voucher];

      const fixedAmountVouchers = newVouchers.filter(
        (v) => v.discount?.typ.case === "fixedAmount",
      );

      const percentageAmountVouchers = newVouchers.filter(
        (v) => v.discount?.typ.case === "percentage",
      );

      const newDiscounts = {
        ...discounts,
        vouchers: [...fixedAmountVouchers, ...percentageAmountVouchers],
      };

      saveDiscounts(newDiscounts);

      return {
        discounts: newDiscounts,
      };
    });

    if (item) {
      addDiscountToItem(item, { case: "voucherId", value: voucher.id });
    }
  };

  const addFantickets: UseItems["addFantickets"] = (fanTicket) => {
    set(({ items }) => {
      const newFanticketItem: CartItem[] = items
        ?.filter((item) =>
          item.eventOrBundle.case === "event"
            ? fanTicket?.eventIds?.includes(item.eventOrBundle.value.id)
            : item.eventOrBundle.value?.eventIds.every((eventId) =>
                fanTicket?.eventIds?.includes(eventId),
              ),
        )
        ?.map((item) => {
          return {
            ...item,
            upgrades: [SeatUpgrade.FANTICKET],
          };
        });

      const newItems = [
        ...newFanticketItem,
        items.filter(
          (item) =>
            !(item.eventOrBundle.case === "event"
              ? fanTicket?.eventIds?.includes(item.eventOrBundle.value.id)
              : item.eventOrBundle.value?.eventIds.every((eventId) =>
                  fanTicket?.eventIds?.includes(eventId),
                )),
        ),
      ].flat();

      setShipmentMethod(ShipmentMethod.POSTAL);
      saveCartItems(newItems);

      return {
        items: newItems,
        uiItems: newItems,
      };
    });
  };

  const addFanticket: UseItems["addFanticket"] = (item: CartItem) => {
    set(({ items }) => {
      const index = findItemIndex(items, item);
      if (index === -1) return {};

      const newItems = items?.map((item) => {
        if (index !== items.indexOf(item)) return item;
        return {
          ...item,
          upgrades: [SeatUpgrade.FANTICKET],
        };
      });

      setShipmentMethod(ShipmentMethod.POSTAL);
      saveCartItems(newItems);
      return { items: newItems, uiItems: newItems };
    });
  };

  const removeFantickets: UseItems["removeFantickets"] = () => {
    set(({ items }) => {
      const newItems: CartItem[] = items?.map((item) => {
        return {
          ...item,
          upgrades: undefined,
        };
      });

      saveCartItems(newItems);
      return { items: newItems, uiItems: newItems };
    });
  };

  const removeFanticket: UseItems["removeFanticket"] = (item: CartItem) => {
    set(({ items }) => {
      const index = findItemIndex(items, item);
      if (index === -1) return {};

      const newItems = items?.map((item) => {
        if (index !== items.indexOf(item)) return item;
        return {
          ...item,
          upgrades: undefined,
        };
      });

      saveCartItems(newItems);
      return { items: newItems, uiItems: newItems };
    });
  };

  const removeVoucher: UseItems["removeVoucher"] = (
    voucherId,
    removeFromItem = true,
  ) => {
    set(({ discounts }) => {
      const newDiscounts = {
        ...discounts,
        vouchers: discounts.vouchers.filter(
          (voucher) => voucher.id !== voucherId,
        ),
      };

      saveDiscounts(newDiscounts);

      return {
        discounts: newDiscounts,
      };
    });

    if (removeFromItem) {
      removeDiscountFromItem({ case: "voucherId", value: voucherId });
    }
  };

  const addBenefit: UseItems["addBenefit"] = (benefit, item) => {
    set(({ discounts }) => {
      const newBenefits = [...discounts.benefits, benefit];

      const fixedAmountbenefits = newBenefits.filter(
        (b) => b.discount?.typ.case === "fixedAmount",
      );

      const percentageAmountBenefits = newBenefits.filter(
        (b) => b.discount?.typ.case === "percentage",
      );

      const newDiscounts = {
        ...discounts,
        benefits: [...fixedAmountbenefits, ...percentageAmountBenefits],
      };

      saveDiscounts(newDiscounts);

      return {
        discounts: newDiscounts,
      };
    });

    if (item) {
      addDiscountToItem(item, { case: "benefitId", value: benefit.id });
    }
  };

  const removeBenefit: UseItems["removeVoucher"] = (
    benefitId,
    removeFromItem = true,
  ) => {
    set(({ discounts }) => {
      const newDiscounts = {
        ...discounts,
        benefits: discounts.benefits.filter(
          (benefit) => benefit.id !== benefitId,
        ),
      };

      saveDiscounts(newDiscounts);

      return {
        discounts: newDiscounts,
      };
    });

    if (removeFromItem) {
      removeDiscountFromItem({ case: "benefitId", value: benefitId });
    }
  };

  const addDiscountToItem = (
    item: CartItem,
    discount: PlainMessage<DiscountConfig>["reference"],
  ) => {
    set(({ items }) => {
      const itemIndex = items.findIndex(
        ({ section, price, eventOrBundle, series, seat }) =>
          series.id === item.series.id &&
          eventOrBundle.case === item.eventOrBundle.case &&
          eventOrBundle.value.id === item.eventOrBundle.value.id &&
          section.id === item.section.id &&
          price.id === item.price.id &&
          seat?.id === item.seat?.id,
      );

      const newItems: CartItem[] =
        item.quantity === 1
          ? [
              ...items.slice(0, itemIndex),
              { ...item, discount },
              ...items.slice(itemIndex + 1),
            ]
          : [
              ...items.slice(0, itemIndex),
              { ...item, quantity: item.quantity - 1 },
              {
                ...item,
                quantity: 1,
                discount,
              },
              ...items.slice(itemIndex + 1),
            ];

      saveCartItems(newItems);

      return {
        items: newItems,
        uiItems: newItems,
      };
    });
  };

  const removeDiscountFromItem = (
    discount: PlainMessage<DiscountConfig>["reference"],
  ) => {
    set(({ items }) => {
      const itemIndex = items.findIndex(
        (item) =>
          item.discount?.case === discount.case &&
          item.discount?.value === discount.value,
      );

      if (itemIndex === -1) {
        return { items };
      }

      const newItems = items.map((item, i) => {
        return itemIndex === i ? { ...item, discount: undefined } : item;
      });

      const newItemsUnique: CartItem[] = [];

      for (const [index, newItem] of newItems.entries()) {
        if (
          newItems.findIndex(
            (i) =>
              i.series.id === newItem.series.id &&
              i.eventOrBundle.case === newItem.eventOrBundle.case &&
              i.eventOrBundle.value.id === newItem.eventOrBundle.value.id &&
              i.section.id === newItem.section.id &&
              i.price.id === newItem.price.id,
          ) === index
        ) {
          newItemsUnique.push(newItem);
        } else {
          const uniqueItemIndex = newItemsUnique.findIndex(
            (i) =>
              i.series.id === newItem.series.id &&
              i.eventOrBundle.case === newItem.eventOrBundle.case &&
              i.eventOrBundle.value.id === newItem.eventOrBundle.value.id &&
              i.section.id === newItem.section.id &&
              i.price.id === newItem.price.id,
          );

          if (newItemsUnique[uniqueItemIndex]) {
            newItemsUnique[uniqueItemIndex].quantity += newItem.quantity;
          }
        }
      }

      saveCartItems(newItemsUnique);

      return {
        items: newItemsUnique,
      };
    });
  };

  const addGiftcard = (giftcard: UseItems["giftcards"][number]) => {
    set(({ giftcards }) => {
      const newGiftcards = [...giftcards, giftcard];

      saveGiftcards(newGiftcards);

      return {
        giftcards: newGiftcards,
      };
    });
  };

  const removeGiftcard = (giftcardId: string) => {
    set(({ giftcards }) => {
      const newGiftcards = giftcards.filter(
        (giftcard) => giftcard.id !== giftcardId,
      );

      saveGiftcards(newGiftcards);

      return {
        giftcards: newGiftcards,
      };
    });
  };

  const redeemGiftcard = (giftcard: PlainMessage<Giftcard>) => {
    set(({ discounts }) => {
      const newDiscounts = {
        ...discounts,
        giftcards: [...discounts.giftcards, giftcard],
      };

      saveDiscounts(newDiscounts);

      return {
        discounts: newDiscounts,
      };
    });
  };

  const releaseGiftcard = (giftcardId: string) => {
    set(({ discounts }) => {
      const newDiscounts = {
        ...discounts,
        giftcards: discounts.giftcards.filter(
          (giftcard) => giftcard.id !== giftcardId,
        ),
      };

      saveDiscounts(newDiscounts);

      return {
        discounts: newDiscounts,
      };
    });
  };

  const addSeatUpgrade = (upgrade: UseItems["seatUpgrades"][number]) => {
    set(({ seatUpgrades }) => {
      const oldSeatUpgrades = seatUpgrades.find(
        (seatUpgrade) =>
          seatUpgrade.referencedOrderId === upgrade.referencedOrderId &&
          seatUpgrade.seatUpgrade === upgrade.seatUpgrade,
      )!;

      if (!oldSeatUpgrades) {
        const newSeatUpgrades = [...seatUpgrades, upgrade];

        saveSeatUpgrades(newSeatUpgrades);

        return {
          seatUpgrades: newSeatUpgrades,
        };
      }

      const mergedUpgrade = {
        ...oldSeatUpgrades,
        lineItems: [
          ...oldSeatUpgrades.lineItems,
          ...upgrade.lineItems.filter(
            (lineItem) =>
              !oldSeatUpgrades.lineItems.some(
                (item) => lineItem.id === item.id,
              ),
          ),
        ],
      };

      const newSeatUpgrades = [
        ...seatUpgrades.filter(
          (seatUpgrade) =>
            seatUpgrade.referencedOrderId !== upgrade.referencedOrderId ||
            seatUpgrade.seatUpgrade !== upgrade.seatUpgrade,
        ),
        mergedUpgrade,
      ];

      saveSeatUpgrades(newSeatUpgrades);

      return {
        seatUpgrades: newSeatUpgrades,
      };
    });

    if (upgrade.seatUpgrade === SeatUpgrade.FANTICKET) {
      setShipmentMethod(ShipmentMethod.POSTAL);
    }
  };

  const removeSeatUpgrade = (upgrade: PlainMessage<SeatUpgradeConfig>) => {
    set(({ seatUpgrades }) => {
      const oldSeatUpgrades = seatUpgrades.find(
        (seatUpgrade) =>
          seatUpgrade.referencedOrderId === upgrade.referencedOrderId &&
          seatUpgrade.seatUpgrade === upgrade.seatUpgrade,
      );

      if (!oldSeatUpgrades) {
        return {
          seatUpgrades,
        };
      }

      const mergedUpgrade = {
        ...oldSeatUpgrades,
        lineItems: oldSeatUpgrades.lineItems.filter(
          (lineItem) => !upgrade.lineItemIds.includes(lineItem.id),
        ),
      };

      const newSeatUpgrades = [
        ...seatUpgrades.filter(
          (seatUpgrade) =>
            seatUpgrade.referencedOrderId !== upgrade.referencedOrderId ||
            seatUpgrade.seatUpgrade !== upgrade.seatUpgrade,
        ),
        mergedUpgrade,
      ].filter((seatUpgrade) => seatUpgrade.lineItems.length);

      saveSeatUpgrades(newSeatUpgrades);

      return {
        seatUpgrades: newSeatUpgrades,
      };
    });
  };

  return {
    setItems,
    currency: "EUR",
    removeItem,
    setUiItems,
    setTimer,
    setReservationToken,
    customer: null,
    setShipmentMethod,
    shipmentMethod: null,
    items: [],
    uiItems: [],
    giftcards: [],
    seatUpgrades: [],
    discounts: {
      vouchers: [],
      benefits: [],
      giftcards: [],
    },
    addVoucher,
    removeVoucher,
    addBenefit,
    removeBenefit,
    loading: false,
    manipulating: false,
    reservationToken: null,
    cartTimer: null,
    reserveSeat,
    cancelSeat,
    setSeats,
    reservationTokenFetched: false,
    addFanticket,
    addFantickets,
    removeFanticket,
    removeFantickets,
    addGiftcard,
    removeGiftcard,
    redeemGiftcard,
    releaseGiftcard,
    addSeatUpgrade,
    removeSeatUpgrade,
  };
});

function doMagic(register: ReserveCancelRegister) {
  const { loading } = useCart.getState();

  if (loading) {
    if (cacheMergeTimeout) {
      clearTimeout(cacheMergeTimeout);
    }

    cache.push(register);
    cacheMergeTimeout = setTimeout(() => {
      logIfDev("now the cache should be merged");
    }, 1000);

    return;
  }

  reserveTimeout = setTimeout(async () => {
    useCart.setState({ loading: true });
    const { items: currentItems, uiItems: currentUiItems } = useCart.getState();

    try {
      const { items: newItems } = await makeRequests(
        structuredClone(currentItems),
        structuredClone(currentUiItems),
      );

      useCart.setState({
        loading: false,
        manipulating: false,
        items: structuredClone(newItems),
        uiItems: structuredClone(newItems),
      });

      saveCartItems(newItems).catch(logIfDev);
    } catch (err: TypeError | any) {
      useCart.setState({
        loading: false,
        manipulating: false,
        items: currentItems,
        uiItems: currentItems,
      });

      let description: string =
        "Die gewünschte Anzahl an Tickets konnte nicht reserviert werden.";
      switch (err.message) {
        case "[permission_denied] reservation limit exceeded":
          description = t.error_messages.reservation_limit_exceeded;
          break;
        case "[permission_denied] event series limit exceeded":
          description = t.error_messages.event_series_limit_exceeded;
          break;
        case "[permission_denied] event limit exceeded":
          description = t.error_messages.event_limit_exceeded;
          break;
        case "[permission_denied] section limit exceeded":
          description = t.error_messages.section_limit_exceeded;
          break;
      }

      toast({
        description,
        variant: "destructive",
      });
    }
  }, 1000);
}
